<template>
  <div>
    <div class="tw-bg-red-100 tw-mb-32">
      <ml-container>
        <div class="tw-flex tw-justify-end">
          <!--
          <a href="#" class="tw-font-semibold tw-flex tw-items-center tw-leading-relaxed tw-text-gray-n4">
            <edit2-icon class="tw-h-5 tw-w-5 tw-mr-2" />
            Edit profile info
          </a>
          -->
        </div>
        <div class="tw-flex tw-justify-center tw-transform tw-translate-y-2/4">
          <div class="tw-w-52 tw-h-52 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-brown tw-relative">
            <v-avatar size="208" class="tw-w-52 tw-h-52 tw-relative">
              <v-img :src="user.avatar"></v-img>
            </v-avatar>
            <Avatar activator-class="tw-absolute tw-right-0 tw-bottom-0 tw-text-white tw-bg-gray-n4 tw-w-12 tw-h-12" />
          </div>
        </div>
      </ml-container>
    </div>
    <div class="tw-text-center tw-leading-relaxed tw-pb-40">
      <h1 class="tw-font-semibold tw-text-lg tw-text-black">{{ user.name }}</h1>
      <span class="tw-text-gray-n3 tw-text-sm">
        {{ user.email }}
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Slug from 'slug';
import MlContainer from "@/components/layout/MlContainer";
//import { Edit2Icon } from "vue-feather-icons";
import Avatar from "@/components/Avatar";
import i18n from "@/i18n";
export default {
  middleware: ['auth', 'vendor'],
  components: {
    Avatar,
    MlContainer,
   // Edit2Icon,
  },
  metaInfo() {
    return {
      title: i18n.t('profile'),
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }],

    }
  },
  data() {
    return {
      edit: false,
      valid: true,
      errors: [],
      lazy: true,
      company: {
        id: null,
        slug: ''
      },
      newlyweds: [],
      countries: [],
      searchCities: null,
      loadingCities: false,
      cities: [],
      loading: false,
      item: {
        event_id: null,
        type_id: null,
        first_name: '',
        last_name: ''
      },
      selectedFile: null,
      file: null,
      rules: {
        required: value => !!value || 'Required.',
        zip: v => /^[0-9]{5}([- ]?[0-9]{4})?$/.test(v) || 'U.S. postal code required (ZIP+4 format)'
      },
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
      // Google Maps API
      location: '',
      searchResults: [],
      service: null,
      filterArea: {},
      filterCountry: [],
      selectedCities: [],
      searchGoogle: false
      // END
    }
  },
  mounted() {
    this.fetchCountries();
    this.getCompany();

  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    url() {
      var url = window.location.origin;
      return url;
    },
    slug() {
      return Slug(this.company.name);
    }
  },
  watch: {
    selectedCities: function () {

      console.log("selectedCities");
      if (!this.selectedCities || this.selectedCities === null) {
        this.filterCountry = [];
        return;
      }

      this.filterArea = {
        city_name: this.selectedCities.city_name,
        code: this.selectedCities.code,
        country: this.selectedCities.country,
        google_place_id: this.selectedCities.id

      };
      if (!Number.isInteger(this.selectedCities.id)) this.fetchAreas(this.filterArea);


    },
    searchCities(val) {

      if (this.searchCities == null || this.searchCities.length < 3) {
        return
      }

      setTimeout(function () {
        this.service.getPlacePredictions({
          input: val,
          componentRestrictions: {country: ['us','pr']},
          types: ['(regions)']
        }, this.displaySuggestions)
      }.bind(this), 300);

    }
  },
  methods: {
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

      if (!this.company.name) {
        label = "Name required.";
        this.errors.push(label);
        this.errors["name"] = label;
      }

      if (!this.company.owner_first_name) {
        label = "First name required.";
        this.errors.push(label);
        this.errors["owner_first_name"] = label;
      }

      if (!this.company.owner_last_name) {
        label = "Last name required.";
        this.errors.push(label);
        this.errors["owner_last_name"] = label;
      }

      if (!this.company.address1) {
        label = "Address1 required.";
        this.errors.push(label);
        this.errors["address1"] = label;
      }

      if (!this.company.address2) {
        label = "Address2 required.";
        this.errors.push(label);
        this.errors["address2"] = label;
      }

      if (!this.company.city && !this.company.state) {
        label = "City required.";
        this.errors.push(label);
        this.errors["city"] = label;
      }

      if (!this.company.zip) {
        label = "Zip required.";
        this.errors.push(label);
        this.errors["zip"] = label;
      }else if(!this.validZip(this.company.zip)){
        label = "U.S. postal code required (ZIP+4 format).";
        this.errors.push(label);
        this.errors["zip"] = label;
      }


      if (!this.company.phone) {
        label = "Phone required.";
        this.errors.push(label);
        this.errors["phone"] = label;
      }else if(!this.validPhone(this.company.phone)){
        label = "U.S. phone format required (E.g (308)-135-7895 or 308-135-7895 or 308135-7895 or 3081357895).";
        this.errors.push(label);
        this.errors["phone"] = label;
      }

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    validZip: function (v) {
      var re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      return re.test(v);
    },
    validPhone: function (v) {
      var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return re.test(v);
    },
    onSubmit(){
      if(this.validateForm()) this.updateItem();
    },
    clearAreas() {
      console.log("clearAreas...");
      this.MapsInit();
      //this.selectedCities = null;
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      //this.searchResults = predictions.map(prediction => prediction.description)
      this.searchResults = predictions.map(prediction => prediction.terms);

      let data = predictions.map(prediction => prediction);

      this.cities = data.map(t => ({

        id: t.place_id,
        description: t.description,
        city_name: t.terms[0].value,
        code: t.terms[1] ? t.terms[1].value : null,
        country: t.terms[2] ? t.terms[2].value : null,
      }));

    },
    updateSlug: function (val) {
      this.company.slug = val;
    },
    saveSlug: function (val) {
      //console.log(val);
      this.company.slug = val;
      this.updateItem();
    },
    resetSlug() {
      this.company.slug = null;
    },
    async updateItem() {




      if(!this.validateForm()){
        return;
      }

      // await this.$refs.form.validate();
      if (!this.valid) return;

      this.loading = true;

      let uri = '/api/vendor/companies/' + this.company.id;
      this.$http.patch(uri, this.company)
          .then((response) => {
            this.$toast.success(response.data.message, "Success")
            //this.$router.push({ name: 'vendors.dashboard' })
          }).finally(() =>
          this.loading = false,
      );
    },
    getCompany() {
      this.loading = true;
      //let uri = '/api/events/' + this.$route.params.id;
      //let uri = '/api/events/1';
      let uri = '/api/vendor/companies';
      this.$http.get(uri)
          .then(({data}) => {
            this.company = data.data,
                this.cities = this.cities.concat(data.data.city)
            this.selectedCities = this.company.city;
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false,
      )

    },
    fetchCountries() {
      this.loading = true;
      this.$http.get('api/countries')
          .then(({data}) => {
            this.countries = data.data
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchCities(query) {

      const params = {
        "filter[name]": query,
        "filter[country_id]": this.company.countries,
        "page": this.$route.query.page
      };

      this.loadingCities = true;
      this.$http.get('api/cities', {params})
          .then(({data}) => {
            this.cities = data.data
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() =>
              this.loadingCities = false
          )

    },
    async fetchAreas(val) {
      console.log("fetchAreas...");
      //let query = (val.city_name+'/'+val.code).toString();
      let query = val;

      const params = {
        "filter[name]": query.city_name,
        "google_place_id": query.google_place_id,
        "filter[country_code]": query.code.substring(0, 2),
        //"filter[filter_all]" : query,
        "page": this.$route.query.page
      };

      await this.$http.get('api/areas', {params})
          .then(({data}) => {
            this.cities = data.data;
            this.selectedCities = this.cities[0];
            this.company.state = this.cities[0].country;
            this.company.city = this.cities[0];
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          });

      //this.company.state.id = this.cities.map(a => a.country_id);


    },
  }
}
</script>
