
<template>
      <v-dialog
      v-model="editMyaccount"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"

      >
            <template v-slot:activator="{ on, attrs }">

                <v-btn icon small v-bind="attrs" v-on="on" :class="activatorClass">
                        <camera-icon class="tw-h-4 tw-w-4" />
                </v-btn>

            </template>

       <v-card>
           <v-toolbar
            dark
            color="blue-grey darken-2"
                >
                    <v-btn
                        icon
                        dark
                        @click="editMyaccount = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ $t('general.avatar') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        :disabled="!valid"
                        @click="updateItem();editMyaccount = false"
                        >
                        {{ $t('general.close') }}
                        </v-btn>
                    </v-toolbar-items>
        </v-toolbar>

                <v-container fluid>


                            <!-- Image -->
                            <v-card
                                elevation="3"
                                max-width="555"
                                class="mx-auto"
                                >

                                <div v-if="item.id">

                                <v-card-text>
                                    
                                    <clipper-basic
                                    v-if="imgURL"
                                    :exif="false"
                                    :ratio="1"
                                    :wrap-ratio="1"
                                    :init-width="30"
                                    :init-height="30"
                                     class="my-clipper" ref="clipper" :src="imgURL">
                                    </clipper-basic>
                                    <div v-else-if="item.avatar && !resultURL">
                                    
                                        <v-avatar size="100">
                                            <img class="result" ref="avatar" :src="item.avatar" :alt="item.name" >
                                        </v-avatar>
                                    </div>
                                    

                                        <v-carousel
                                        
                                                v-if="item.images.length > 0 && 1==2"
                                                
                                                >
                                                
                                                <div  v-for="(item,i) in item.images"
                                                :key="i">
                                                

                                                    <v-carousel-item                                  
                                                        :src="item.src"
                                                        reverse-transition="fade-transition"
                                                        transition="fade-transition"
                                                        >
                                                    <span @click="destroyImage(item.id)" 
                                                
                                                    class="btn py-1 px-2 bg-danger text-white position-absolute" 
                                                    style="top: 0; right: 0">
                                                    <i class="fa fa-trash-alt"></i>
                                                    </span>
                                    
                                                    </v-carousel-item>
                                            
                                                </div>
                                            
                                        </v-carousel>


                                </v-card-text>




                                <v-card-actions>
                                    <clipper-upload :exif="false" class="btn" v-model="imgURL">{{ $t('general.upload_image') }}</clipper-upload>

                                    <v-spacer/>
                                    <spinner x-small v-if="loading"></spinner> 
                                    <v-btn small text @click="getResult" v-if="imgURL">
                                        {{ $t('general.save') }}
                                        <v-icon small right>
                                            mdi-content-save-outline
                                        </v-icon>
                                    </v-btn>
                                
                                </v-card-actions>

           
                                </div>
                            </v-card>

                </v-container>


       </v-card>

</v-dialog>

</template>


<script>

import { mapGetters } from 'vuex'
import { CameraIcon } from 'vue-feather-icons';

/*
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
*/
export default {
  middleware: 'auth',
  props: ['activatorClass'],
  components: {
    CameraIcon,
  },
  data() {
        return {
            editMyaccount: false,
            valid: true,
            lazy: true,             
            loading: false,
            selectedFile: null,
            file: null,
            rules: {
                required: value => !!value || 'Required.'
            },
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            resultURL: '',
            imgURL: ''
        }
    },
computed: mapGetters({
    item: 'auth/user',
  }),
  methods:{
    async getResult() {
        this.loading = true;
        const canvas = this.$refs.clipper.clip();//call component's clip method
        this.resultURL = canvas.toDataURL("image/jpeg", 1);//canvas->image
        this.onUpload2(canvas);    
    },
    getItem() {
      this.loading = true;
      this.$http.get('api/user')
          .then(({data})  => {
              this.item = data.data
          })
          .catch(error => {
              console.log('Error loading orders data: ' + error)
          }).finally(() => 
          this.loading = false,
          )
    },
    updateItem(){
        /*
        let uri = '/api/user/'+this.item.id;
        this.$http.patch(uri, this.item)
        .then((response) => {
            this.$toast.success(response.data.message, "Success"),
            this.$store.dispatch('auth/fetchUser')
        });
        */
    },
    onFileSelected(event){
        console.log(event);
        if(event == null) return;
        //this.selectedFile = event.target.files[0];
        this.selectedFile = event;
        this.onUpload();
    },
    onUpload2(canvas){


        var self = this;
       
        canvas.toBlob(function(blob){


        const formData = new FormData();
        //formData.append('image', this.selectedFile);
        formData.append('image', blob, self.item.id+'.jpg');
        
        let uri = '/api/user/'+self.item.id+'/avatar';
        self.$http.post(uri, formData, {
            onUploadProgress: uploadEvent => {
                console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100));
            }
        })
        .then((response) => {
            self.$toast.success(response.data.message, "Success");
            //this.$refs.inputFile.reset();
            self.resultURL = '';
            self.imgURL = '';
            self.file = null;
            self.$store.dispatch('auth/fetchUser');
            self.loading = false;
        });

        },'image/jpeg');

    },
    onUpload(){

        const formData = new FormData();
        formData.append('image', this.selectedFile);

        let uri = '/api/user/'+this.item.id+'/avatar';
        this.$http.post(uri, formData, {
            onUploadProgress: uploadEvent => {
                console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100));
            }
        })
        .then((response) => {
            this.$toast.success(response.data.message, "Success");
            //this.$refs.inputFile.reset();
            this.file = null;
            this.$store.dispatch('auth/fetchUser');
        });



    },    
    destroyImage (vId) {
        this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',            
        position: 'center',
        buttons: [
            ['<button><b>YES</b></button>', (instance, toast) => {
                
                this.onImageDelete(vId);

                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

            }, true],
            ['<button>NO</button>', function (instance, toast) {

                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

            }],
        ]            
        });            
    },
    onImageDelete(id){
        if (!this.item.id) return;
        let uri = '/api/user/'+this.item.id+'/avatar/' + id;
        this.$http.delete(uri)
                .then((response) => {
                    this.item.images = response.data.images,
                    this.$toast.success(response.data.message, "Success", { timeout: 1000 });
                });
        
    },

  }
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}
.flex .lg {
  flex-basis: 0;
  flex-grow: 3;
  margin: 3px;
}
.flex .md {
  flex-basis: 0;
  margin: 3px;
  flex-grow: 2;
}
.basic {
  max-width: 700px;
  min-width: 270px;
}
.empty-height {
  height: 500px;
}
</style>





